/* eslint-disable jsx-a11y/no-autofocus */
import PageMeta from '@components/PageMeta';
import TextField from '@components/TextField';
import Translate from '@components/Translate';
import { useAuth } from '@context/AuthContext';
import { AuthType } from '@helpers/AuthClient';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import { Button, Content, Form, Message } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  email: string;
}

interface ForgotPasswordViewProps {
  authType: AuthType;
  defaultValues?: Partial<FormValues>;
}

const ForgotPasswordView: FC<ForgotPasswordViewProps> = ({ authType, defaultValues }) => {
  const { t } = useTranslation(['auth', 'validation', 'pages']);

  const { authClient } = useAuth();

  const formProps = useForm<FormValues>({ defaultValues });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit: SubmitHandler<FormValues> = ({ email }) => {
    setIsSuccess(false);
    setIsLoading(true);

    authClient
      .sendResetPasswordEmail(email, authType)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(error => {
        formProps.setError('email', { message: error.message });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const email = formProps.watch('email');

  const loginUrl = getAuthPageUrl('login', authType);
  const registerUrl = getAuthPageUrl('register', authType);

  return (
    <AuthTemplate>
      <PageMeta
        description={t('pages:forgotPasswordDescription')}
        title={t('pages:forgotPassword')}
      />
      <AuthTemplate.Content>
        {isSuccess && (
          <Message color="success">
            <Message.Body>{t('resetPasswordEmailSent')}</Message.Body>
          </Message>
        )}
        <Content>
          <p>{t('forgotPasswordInstructions')}</p>
        </Content>
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
            <TextField<FormValues>
              autoComplete="username"
              autoFocus
              id="email"
              label={t('common:email')}
              name="email"
              type="email"
              validation={{ required: t('validation:required', { field: t('common:email') }) }}
            />
            <Form.Field alignItems="center" kind="group">
              <Form.Control>
                <Button color="primary" loading={isLoading}>
                  {t('common:submit')}
                </Button>
              </Form.Control>
            </Form.Field>
          </form>
        </FormProvider>
        <AuthTemplate.Footer>
          <Translate
            components={{
              login: <Link to={loginUrl} state={{ email }} />,
              signUp: <Link to={registerUrl} state={{ email }} />,
            }}
            message={t('haveOrNeedAccount')}
          />
        </AuthTemplate.Footer>
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default ForgotPasswordView;
